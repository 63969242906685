.chart-status {
    display: flex;
}
.recharts-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
}
.chart-status > h3 {
    font-size: 20px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.336);
    padding: 0 0 10px;
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    gap: 15px;
}
.section-item h3 {
    font-size: 20px;
    padding: 0 0 10px;
    margin: 0 0 10px;
}
.item-action-type h3 {
    font-size: 17px;
    padding: 0 0 10px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.item-action-type p {
    font-size: 11px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.list-item-status h4 {
    font-size: 13px;
    margin: 0;
    line-height: 17px;
}
.chart-status > h3::before {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    background-color: rgb(4, 102, 194);
}
.chart-status-right h3 {
    font-size: 20px;
    padding: 0 0 10px;
    margin: 0;
}
.chart-status-right h4 {
    font-size: 36px;
    padding: 0 0 20px;
    margin: 0;
    color: #0088fe;
    line-height: 30px;
}
.chart-status-right p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 40px);
    cursor: pointer;
}
.total-statistics h3,
.total-statistics h4,
.total-statistics p {
    margin: 0;
}
.title-total-statistics h3 {
    font-weight: 400;
    font-size: 14px;
}
.title-total-statistics h4 {
    font-size: 28px;
}
.infor {
    margin-right: 40px;
}
.title {
    font-size: 16px;
    font-weight: 600;
    color: #2a61a3;
}

.order-pending {
    height: 20px;
    width: 40px;
    background-color: #0088fe;
    margin-right: 8px;
}
.order-confirmed {
    height: 20px;
    width: 40px;
    background-color: #00c49f;
    margin-right: 8px;
}
.order-in_progress {
    height: 20px;
    width: 40px;
    background-color: #ffbb28;
    margin-right: 8px;
}
.order-completed {
    height: 20px;
    width: 40px;
    background-color: #ff8042;
    margin-right: 8px;
}
.order-canceled {
    height: 20px;
    width: 40px;
    background-color: red;
    opacity: 70;
    margin-right: 8px;
}

.list-status {
    display: flex;
    align-items: center;
}
.chart-status .infor {
    background-color: white;
    border: 1px solid #acaeb1;
    padding: 10px;
    border-radius: 8px;
}
.shadow-statistics {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 8px #0000003a;
}
.item-label h3,
.item-label h4 {
    color: #000;
    margin: 0;
}
.item-label h4 {
    font-weight: 400;
}

@media only screen and (max-width: 768px) {
    .chart-status-right p {
        font-size: 10px;
    }
    .chart-status-right h4 {
        font-size: 26px;
        line-height: 22px;
    }
}
